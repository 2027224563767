import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { SEO } from '../components/SEO';
import Layout, { Wrapper } from '../layouts/Layout';
import { useCurrentUser } from '../services/currentUser';
import { toUserName } from '../services/usersPublic';
import styled from '../styled';

const IndexPage = () => {
  const { isAuthenticated, user, loadingUser } = useCurrentUser();

  return (
    <Layout>
      <SEO
        title="Activity"
        siteUrl="https://bloggingfordevs.com"
        description="Learn how to grow your blog strategically as a developer."
        pathname="activity/"
        noIndex
      />
      <Wrapper>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          style={{ textAlign: 'center', marginBottom: '12px' }}
        >
          Welcome, {user ? toUserName(user.user) : '...'} 👋
        </Typography>
        <div style={{ marginBottom: '250px' }}>
          {isAuthenticated ? (
            <Typography
              variant="body1"
              component="p"
              style={{ textAlign: 'center' }}
            >
              Check back soon for something neat :)
            </Typography>
          ) : null}
          {!isAuthenticated && !loadingUser && (
            <Typography variant="body1">
              You need to log in to see this page
            </Typography>
          )}
        </div>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
